import React from 'react';
import { Provider as StoreProvider } from 'react-redux';

// Stylesheets
import './App.scss';

// Contexts
import { Provider as ApiProvider } from './providers/ApiClient/Context';

// Services
import './bugsnag';
import './analytics';
import cacheStorage from './services/cache-storage'; // TODO: Remove this later
import amplify from './amplify';
import store from './store';
import session from './shared/session';
import apiClient from './shared/api';

import { Hub } from 'aws-amplify/utils';

// Components
import Routes from './Routes';
import ErrorBoundary from './components/ErrorBoundary';

// Listeners
import initializeListener from './listeners/initialize';
import sessionChangeListener from './listeners/session-change';
import requestAuthenticationListener from './listeners/request-authentication';
import requestTokensListener from './listeners/request-tokens';
import authenticationErrListener from './listeners/authentication-err';
import authenticationOkListener from './listeners/authentication-ok';
import refreshTokenChangeListener from './listeners/refresh-token-change';
import signOutListener from './listeners/sign-out'; // Disconnect

const logSessionStart = function (logType) {
  return function ({ channel, payload }) {
    if (channel === 'authentication_ok') {
      // TODO: Start a new session if not started yet.
      // TODO: Check user last session time.
      // TODO: Check user last action time.
      // console.log('User id', payload.user.id);
      store.dispatch({ type: 'SESSION', payload: { event: 'start', userId: payload.user.id } });
    }

    if (channel === 'authentication_err') {
      store.dispatch({ type: 'SESSION', payload: { event: 'error', data: { ...payload } }});
    }
  };
};

// Hooks
Hub.listen('initialize', initializeListener);
Hub.listen('session_change', sessionChangeListener);
Hub.listen('request_authentication', requestAuthenticationListener);
Hub.listen('request_tokens', requestTokensListener);
Hub.listen('authentication_ok', authenticationOkListener);
Hub.listen('authentication_err', authenticationErrListener);
Hub.listen('refresh_token_change', refreshTokenChangeListener);
Hub.listen('sign_out', signOutListener);

Hub.listen('authentication_ok', logSessionStart('success'));
Hub.listen('authentication_err', logSessionStart('error'));

// Debug only
if (process.env.NODE_ENV === 'development') {
  window.store = store;
  window.cacheStorage = cacheStorage;
  window.apiClient = apiClient;
  window.amplify = amplify;
  window.session = session;
}

setInterval(() => {
  Hub.dispatch('request_tokens', {});
}, 10 * 60000);

Hub.dispatch('initialize', {});

const App = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={(<span>Carregando...</span>)}>
        <StoreProvider store={store}>
          <ApiProvider value={apiClient}>
            <Routes />
          </ApiProvider>
        </StoreProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
