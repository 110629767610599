import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

// State selectors
import getCurrentUser from '../../selectors/getCurrentUser';
import getCurrentProject from '../../selectors/getCurrentProject';

// Singletons
import session from '../../../shared/session';
import bugsnag from '../../../bugsnag';

/**
 * Api search services
 * @param {Object} params
 * @param {Object} params.input
 * @param {Object} params.context
 */
async function loadServicesApi({ input, context }) {
  var requestParams = {
    url: `${process.env.REACT_APP_API_ENDPOINT}/graphql/query`,
    method: 'post',
    headers: {
      'X-Project-Id': context.project,
      'Authorization': `Bearer ${session.getToken()}`
    },
    data: {
      query: `
        query getServices ($input: FindServicesInput!) {
          getServices (input: $input) {
            error {
              type
              message
            }
            data {
              count
              edges {
                id
                title
                latitude
                longitude
                stepId
                status: state
                startAt: startedAt(timezone: "UTC")
                finishAt: finishedAt(timezone: "UTC")
                estimatedStartAt(timezone: "UTC")
                estimatedFinishAt(timezone: "UTC")
              }
            }
          }
        }
      `,
      variables: {
        input
      }
    }
  };

  const response = await axios.request(requestParams);
  const { getServices } = response.data.data;
  return getServices;
}


const escapeString = (str) => {
  return '"' + str.toString().replace(',', '\\,').replace('"', '\\"') + '"';
}

// store.getState().mapPage.steps.data[stepId].title
// store.getState().mapPage.fields.data[fieldId].title

const selectFieldsHashTable = (state) => {
  return Object.fromEntries(Object.entries(state.mapPage.fields.data).map(([id, obj]) => [id, obj.title]));
}

const selectStepsHashTable = (state) => {
  return Object.fromEntries(Object.entries(state.mapPage.steps.data).map(([id, obj]) => [id, obj.title]));
}

/**
 * Key [projectId]: resultCount
 */
var resultsHashTable = {};

function* asyncMakeSearch(action) {
  var currentUser = yield select(getCurrentUser);
  var currentProject = yield select(getCurrentProject);
  var stateSearch = yield select(state => state.search);
  var stepsMap = yield select(selectStepsHashTable);
  var fieldsMap = yield select(selectFieldsHashTable);

  var context = {
    token: currentUser.token,
    project: currentProject.id
  };

  var searchOptions = {}

  // Querystring
  if (stateSearch.q && stateSearch.q.length > 0) {
    searchOptions.q = stateSearch.q;
  }

  // ID
  if (stateSearch.id && stateSearch.id.length > 0) {
    searchOptions.id = stateSearch.id;
  }

  // Track ID
  if (stateSearch.trackId && stateSearch.trackId.length > 0) {
    searchOptions.trackId = stateSearch.trackId;
  }

  // Type
  if (stateSearch.type && stateSearch.type.length > 0) {
    searchOptions.type = stateSearch.type;
  }

  // Steps
  if (stateSearch.status && stateSearch.status.length > 0) {
    searchOptions.step = stateSearch.status;
  }

  // Fields
  if (stateSearch.properties && Object.keys(stateSearch.properties).length > 0) {
    searchOptions.fields = Object.entries(stateSearch.properties).map(([key, value]) => {
      return {
        id: key,
        value: value
      }
    });
  }

  // Order
  if (stateSearch.orderBy && stateSearch.orderBy.length > 0) {
    searchOptions.order = stateSearch.orderBy;
  }

  var hasQuery = (searchOptions.q && searchOptions.q.length > 0) === true;
  var hasFilters = (searchOptions.step && searchOptions.step.length > 0) || (searchOptions.fields && searchOptions.fields.length > 0) === true;
  var hasCriteria = hasQuery || hasFilters;
  var searchOptionsLog = {
    ...searchOptions
  };

  delete (searchOptionsLog.step);

  if (searchOptions.step && Array.isArray(searchOptions.step)) {
    searchOptionsLog.steps = searchOptions.step
      .map((stepId) => stepsMap[stepId] || '')
      .map(escapeString)
      .join(',');
  }

  if (searchOptions.fields && Array.isArray(searchOptions.fields)) {
    searchOptionsLog.fields = Object.fromEntries(searchOptions.fields
      .map((item) => {
        return [
          fieldsMap[item.id] || item.id,
          item.value.map(escapeString).join(',')
        ]
      }));
  }

  /*
  if (hasCriteria) {
    yield put({
      type: 'EVENT',
      payload: {
        event: 'search',
        content: {
          hasSearch: hasQuery,
          hasFilter: hasFilters,
          query: { ...searchOptions }
        }
      }
    });
  }
  */

  yield put({ type: 'MARKERS.REQUEST' });

  try {
    var response = yield call(loadServicesApi, { input: searchOptions, context });


    if (!hasCriteria) {
      resultsHashTable[currentProject.id] = parseInt(response.data.count);
    }

    var foundCount = parseInt(response.data.count);
    var totalCount = resultsHashTable[currentProject.id];

    if (hasCriteria) {
      yield put({
        type: 'EVENT',
        payload: {
          event: 'search',
          content: {
            hasSearch: hasQuery,
            hasFilter: hasFilters,
            query: {
              ...searchOptionsLog
            },
            foundCount: foundCount,
            totalCount: totalCount,
            wasFailed: (foundCount === 0) || (hasFilters && (foundCount >= totalCount))
          }
        }
      });
    }

    /*
    if ((searchOptions.q && searchOptions.q.length > 0) || (searchOptions.step && searchOptions.step.length > 0) || (searchOptions.fields && searchOptions.fields.length > 0)) {
      yield put({
        type: 'EVENT',
        payload: {
          event: 'search',
          content: {
            hasSearch: (searchOptions.q && searchOptions.q.length > 0) === true,
            hasFilter: (searchOptions.step && searchOptions.step.length > 0) || (searchOptions.fields && searchOptions.fields.length > 0) === true,
            query: { ...searchOptions }
          }
        }
      });
    }
    */

    yield put({
      type: 'MARKERS.FETCH_SUCCESS',
      payload: {
        data: response.data.edges
      }
    });

  } catch (e) {

    yield put({
      type: 'EVENT',
      payload: {
        event: 'search_error',
        content: {
          hasSearch: (searchOptions.q && searchOptions.q.length > 0) === true,
          hasFilter: (searchOptions.step && searchOptions.step.length > 0) || (searchOptions.fields && searchOptions.fields.length > 0) === true,
          query: { ...searchOptionsLog }
        }
      }
    });

    console.warn(e);
    bugsnag.notify(e);

    yield put({ type: 'MARKERS.FETCH_ERROR' });
  }
}

export default asyncMakeSearch;
