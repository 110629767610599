import axios from 'axios';

function Client(settings) {
  this.version = '0.0.1';
  this.endpoint = settings.endpoint || 'http://api.esl.geopointer.com.br:9100';
  this.debug = false;
  this.token = null;

  /**
   * Client user token
   * @param {String} token
   * @returns {Client}
   */
  this.setToken = (token) => {
    this.token = token;
    return this;
  }

  this.project = (projectId) => {
    var clientProject = {

      /**
       * Return project presets (renamed from presets)
       * @returns {Array<Object>}
       */
      statusOptions: async () => {
        var scheme = await axios({
          method: 'GET',
          url: `${this.endpoint}/scheme/project/${projectId}/color.json`
        });

        return scheme.data;
      },

      /**
       * Get filterable options
       * @returns {Array<Object>}
       */
      filterOptions: async () => {
        var response = await clientProject.query(`
          query listFilters {
            filters: getAvailableFilters {
              fixed
              key
              values
            }
          }
        `);

        return response.data.filters;
      },

      markers: {

        /**
         * List/filter markers
         * @param {Object} params
         * @param {String} params.q
         * @param {String} params.status
         * @param {Array<Object>} params.properties
         * @param {String} params.properties.key
         * @param {Array<String>} params.properties.values
         */
        search: async (params = {}) => {
          var variables = {
            status: []
          };

          if (params.q) {
            variables.keywords = params.q;
          }

          if (params.id) {
            variables.id = params.id;
          }

          if (params.title) {
            variables.title = params.title;
          }

          if (params.status) {
            variables.status = params.status;
          }

          if (params.properties) {
            variables.properties = Object.entries(params.properties)
              .map(([key, value]) => {
                return { key, value }
              });
          }

          var response = await clientProject.query(`
            query searchMarkers (
              $keywords: String,
              $status: [String!],
              $properties: [MarkerPropertyValueList]
            ) {
              markers: getMarkers (
                keywords: $keywords,
                status: $status,
                properties: $properties
              ) {
                marker {
                  id
                  title
                  status
                  latitude
                  longitude
                }
              }
            }
          `, variables);

          return response.data.markers.map((row) => row.marker);
        },

        /**
         * Return all markers
         * @returns {Array<Object>}
         */
        getAll: async () => {
          var response = await clientProject.query(`
            query listMarkers {
              markers: getMarkers {
                marker {
                  id
                  title
                  status
                  latitude
                  longitude
                }
              }
            }
          `);

          return response.data.markers.map((row) => row.marker);
        },

        /**
         * Return one marker by ID
         * @param {String} markerId
         * @returns {Object}
         */
        getOne: async (markerId) => {
          var response = await clientProject.query(`
            query searchMarkers ($id: UUID!) {
              markers: getMarkers (id: $id) {
                marker {
                  id
                  title
                  status
                  latitude
                  longitude
                  tasks {
                    id
                    title
                    progress
                  }
                  properties {
                    key
                    value
                  }
                  model {
                    id
                    name
                    downloadUrl
                  }
                  images {
                    id
                    name
                    downloadUrl
                  }
                  documents {
                    id
                    name
                    downloadUrl
                  }
                }
              }
            }
          `, { id: markerId });

          if (response.data.markers.length === 0) {
            return null;
          }

          return response.data.markers[0].marker;
        }
      },

      /**
       * GraphQL query/mutation
       * @param {String} query
       * @param {Object} data
       * @returns {Promise<Object|null>}
       */
      query: async (query, data = null) => {
        var response = await axios({
          method: 'POST',
          url: `${this.endpoint}/graphql/project/${projectId}`,
          data: {
            query: query,
            variables: data
          },
          json: true
        });
        return response.data;
      }
    };
    return clientProject;
  }
};

export default Client;
